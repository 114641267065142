import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ComposerService } from '../composer.service';
import { Presentation, PresentationFrame } from '../../presentation';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentActions, DocumentSelectors } from '../../document/document-store';
import { AddPinnedCommentsService } from '../composer-pinned-comments/add-pinned-comments-service';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { DocumentService } from '../../document/document.service';
import { v4 as uuid } from 'uuid';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthService } from '@common/auth/auth.service';
import { FrameTemplateEditorModal } from '../../document-templates/frame-template-editor-modal/frame-template-editor-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-composer-frame-tray',
  templateUrl: './composer-frame-tray.component.html',
  styleUrls: ['./composer-frame-tray.component.scss'],
})
export class ComposerFrameTrayComponent implements OnInit, OnDestroy {
  private sidePanelOpened = null;
  private subscriptions: Subscription[] = [];

  private frames;
  public draggingFrames = [];
  private currentFrame;
  public editorMode: EditorMode;
  public presentation: Presentation;
  public selectedPlaceholderFrame;
  public selectedIds = [];
  public selectedLineboardFrameIds = [];

  dragging = false;
  isLoading = false;
  message = 'Loading your Presentation. Please wait...';

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  multipleContext = false;
  contextMenuId = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private composerService: ComposerService,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private documentService: DocumentService,
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private matDialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m));
  }

  ngOnInit(): void {
    this.composerService.selectedFrameIdsSubject.next([]);
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private subscribe() {
    this.subscriptions.push(
      this.composerService.presentation.subscribe((pres) => {
        this.presentation = pres;
        if (pres?.presentationFrameOrder?.length) {
          this.frames = pres?.frames;
          if (this.selectedIds.length === 0) {
            // initial loading only
            let orderIdx = 0;
            const slideId = this.route.snapshot.fragment;
            if (slideId) {
              const idx = pres?.presentationFrameOrder.findIndex((id) => id === slideId);
              if (idx === -1) {
                this.router.navigate([], { relativeTo: this.route, skipLocationChange: false }); // if slideId doesn't exist anymore
              } else {
                // find slide and load Frame
                if (idx > 0) {
                  // if idx = 0, no need to load frame again
                  this.composerService.navigateToFrame(slideId);
                  setTimeout(() => {
                    document.getElementById(slideId).scrollIntoView({ behavior: 'auto' });
                  }, 10);
                }
                orderIdx = idx;
              }
            }
            this.selectedIds = [pres?.presentationFrameOrder[orderIdx]];
            this.composerService.selectedFrameIdsSubject.next(this.selectedIds);
          }
        }
      }),
    );

    this.subscriptions.push(
      this.composerService.selectedPlaceholderFrame.subscribe((frame) => {
        this.selectedPlaceholderFrame = frame;
      }),
    );

    this.subscriptions.push(
      this.store.select(DocumentSelectors.toggleChooser).subscribe((toggleChooser) => {
        this.sidePanelOpened = toggleChooser;
      }),
    );

    this.subscriptions.push(
      this.composerService.presentationLoadInProgress.subscribe((loadInProgress) => {
        this.isLoading = loadInProgress;
      }),
    );

    this.subscriptions.push(
      this.composerService.currentFrame.subscribe((currentFrame) => {
        this.currentFrame = currentFrame;
        if (currentFrame?.id && this.selectedIds.length && !this.selectedIds.includes(currentFrame?.id)) {
          this.selectedIds = [currentFrame?.id];
          this.composerService.selectedFrameIdsSubject.next(this.selectedIds);
        }
      }),
    );

    this.subscriptions.push(
      this.composerService.selectedFrameIdsObservable.subscribe((selectedFrameIds) => {
        this.selectedIds = selectedFrameIds;
      }),
    );

    this.subscriptions.push(
      this.composerService.selectedLineboardFrameIdsObservable.subscribe((selectedLineboardFrameIds) => {
        this.selectedLineboardFrameIds = selectedLineboardFrameIds;
      }),
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  trackByFrameId(index: number, frame: any): string {
    return frame.id;
  }

  async insertSlide(num) {
    const frame = {
      type: 'document',
      document: {
        size: { width: 1200, height: 675 },
        id: uuid(),
        elements: [],
      },
    };
    const newFrame = await this.composerService.addPresentationFrame(frame, this.contextMenuId + num);
    this.composerService.setSelectedFrame(newFrame);
    this.composerService.resetSelectedPlaceholderFrame();
  }

  selectFrame(frame: PresentationFrame, mouseEvt: MouseEvent) {
    this.documentService.deselectAllElements();
    let key = 'NONE';
    if (mouseEvt.ctrlKey || mouseEvt.metaKey) {
      key = 'CTRL';
    } else if (mouseEvt.shiftKey) {
      key = 'SHIFT';
    }

    switch (key) {
      case 'CTRL':
        this.ctrlSelect(frame);
        break;
      case 'SHIFT':
        this.shiftSelect(frame);
        break;
      default:
        this.selectedIds = [frame?.id];
        this.loadFrame(frame);
        break;
    }

    this.composerService.selectedFrameIdsSubject.next(this.selectedIds);
  }

  private ctrlSelect(frame) {
    const idx = this.selectedIds.indexOf(frame?.id);
    if (idx > -1) {
      // Already selected
      if (frame.id === this.currentFrame.id) {
        if (this.selectedIds.length > 1) {
          this.selectedIds.splice(idx, 1);
          const newFrameId = this.selectedIds[0];
          const newFrame = this.frames.find((f) => f.id === newFrameId);
          this.loadFrame(newFrame);
        }
      } else {
        this.selectedIds.splice(idx, 1);
      }
    } else {
      // Add to selectedIds
      this.selectedIds.push(frame?.id);
      this.loadFrame(frame);
    }
  }

  private shiftSelect(frame) {
    const orderIds = this.presentation.presentationFrameOrder;

    const targetId = frame?.id;
    const targetIdx = orderIds.indexOf(targetId);
    let firstIdx = -1;
    orderIds.find((id, idx) => {
      if (this.selectedIds.includes(id)) {
        firstIdx = idx;
        return id;
      }
    });

    let from = firstIdx;
    let to = targetIdx;
    if (firstIdx > targetIdx) {
      from = targetIdx;
      to = firstIdx;
    }
    this.selectedIds = orderIds.slice(from, to + 1);
    this.loadFrame(frame);
  }

  private loadFrame(frame: PresentationFrame) {
    if (frame?.id === this.currentFrame?.id) {
      return;
    }
    this.composerService.addFrameIdQuery(frame.id);
    this.documentService.handleDocumentElementEvent({
      element: null,
      eventType: 'dragStarted',
    }); // trigger this event to hide property config bar for selected image elements
    this.composerService.setCurrentFrame(frame);
    this.composerService.setSelectedFrame(frame);
    this.composerService.resetSelectedPlaceholderFrame();
    if (
      this.sidePanelOpened &&
      ['componentEditor', 'collectionFrameLayout', 'gridFrameLayout'].indexOf(this.sidePanelOpened?.slug) !== -1
    ) {
      // close side panel when switching frame
      this.store.dispatch(DocumentActions.toggleChooser({ overlay: null }));
    }
  }

  activatePlaceholder(frame: PresentationFrame) {
    this.composerService.setSelectedPlaceholderFrame(frame);
    this.composerService.resetSelectedFrame();
  }

  onContextMenu(event: MouseEvent, frame: PresentationFrame, idx: number) {
    event.preventDefault();
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    if (this.selectedIds.length > 1 && this.selectedIds.includes(frame?.id)) {
      this.multipleContext = true;
    } else {
      this.multipleContext = false;
    }
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { frame };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
    this.contextMenuId = idx;
  }

  duplicateFrame(frame: PresentationFrame) {
    this.composerService.setCurrentFrame(frame);
    this.composerService.setSelectedFrame(frame);
    this.composerService.composerClipboard.copyFrame(frame);
    this.composerService.composerClipboard.pasteFrame();
  }

  deleteFrame(frame: PresentationFrame) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    if (this.multipleContext) {
      let frames = [];
      this.frames.forEach((f) => {
        if (this.selectedIds.includes(f.id)) frames.push(f);
      });
      this.composerService.deleteFrames(frames);
    } else {
      this.composerService.deleteFrame(frame);
    }
  }

  toggleSkipFrame(frame: PresentationFrame) {
    if (this.multipleContext) {
      this.selectedIds.forEach((id) => {
        const frame = this.frames.find((f) => f.id === id);
        const undoFrame = ObjectUtil.cloneDeep(frame);
        frame.disabled = !frame.disabled;
        this.composerService.handleFrameChanges(frame, undoFrame);
      });
    } else {
      const undoFrame = ObjectUtil.cloneDeep(frame);
      frame.disabled = !frame.disabled;
      this.composerService.handleFrameChanges(frame, undoFrame);
    }
  }

  addComment(frame: PresentationFrame) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    this.contextMenu.closeMenu();
    this.addPinnedCommentsService.addFramePreviewComment(frame.id);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }

    const prevIdx = event.previousIndex;
    const currIdx = event.currentIndex;

    // If there's only one selected item, we simply move it
    if (this.selectedIds.length === 1) {
      moveItemInArray(this.presentation.frames, prevIdx, currIdx);
      if (prevIdx === currIdx) return;
    } else if (this.selectedIds.length > 1) {
      const targetId = this.presentation?.frames[currIdx]?.id;

      // Separate the dragging frames and ensure no duplication of the placeholder frame
      const uniqueDraggingFrames = this.draggingFrames.filter((f) => f.id !== targetId);

      // Remove dragging frames from the current frames
      const remainingFrames = this.presentation.frames.filter(
        (f) => !this.selectedIds.includes(f.id) || f.id === targetId,
      );

      // Determine the insertion point
      let targetIdx = remainingFrames.findIndex((f) => f.id === targetId);
      if (prevIdx < currIdx) {
        targetIdx++;
      }

      // Insert the dragging frames at the calculated position
      const updatedFrames = [
        ...remainingFrames.slice(0, targetIdx),
        ...uniqueDraggingFrames,
        ...remainingFrames.slice(targetIdx),
      ];

      this.presentation.frames = updatedFrames;
      this.changeDetectorRef.detectChanges();
      // Notify the drop container to recalculate its positions
    }
    //Save Presentation and updating UNDO Service
    this.composerService.savePresentation('UPDATE_FRAME_ORDER', this.presentation.frames);
  }

  cdkDragStarted(evt, frame, dropList: CdkDropList) {
    const framePreviewDiv = document.getElementById('framePreview-' + frame.id);
    if (!this.selectedIds.includes(frame.id)) {
      this.selectedIds = [frame?.id];
      this.loadFrame(frame);
      framePreviewDiv.classList.add('selected');
    }
    const preview = document.getElementById('cdkDragPreviewDiv');
    preview.appendChild(framePreviewDiv.cloneNode(true));

    this.draggingFrames = this.presentation.frames.filter((f) => this.selectedIds.includes(f.id));
    this.presentation.frames = [
      ...this.presentation.frames.filter((f) => !this.selectedIds.includes(f.id) || f.id === frame.id),
    ];
    this.changeDetectorRef.detectChanges();

    // Notify the drop container to recalculate its positions
    dropList._dropListRef.start();
    this.dragging = true;
  }

  cdkDragEnded(evt) {
    this.dragging = false;
  }

  async saveAsTemplate(presentationFrame: PresentationFrame) {
    const elements = presentationFrame.document.elements;
    const frame = {
      style: {},
      size: presentationFrame.document.size,
      clipContent: presentationFrame.document.clipContent,
      position: { x: 0, y: 0 },
      type: 'frame',
    };
    if (presentationFrame.document.background) {
      frame.style = presentationFrame.document.background[0].style;
    }
    const authContext = await this.authService.getAuthContext();
    const isOrgAdmin = this.authService.isAdmin();
    const config = {
      data: { authContext, isOrgAdmin, elements, frame, ownerReference: this.documentService.ownerReference },
      panelClass: 'frame-template-editor',
      minWidth: '1200px',
    };
    this.matDialog.open(FrameTemplateEditorModal, config);
  }
}
