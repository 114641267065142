<div *ngIf="!showcase" class="title-bar">
  <div class="flex items-center ml-4 cursor-pointer showcase-logo" (click)="home()">
    <img src="assets/images/showcase_icon.svg" alt="Showcase Icon" class="object-contain mr-4 w-9 h-9" />
    <h6>Showcases</h6>
  </div>
  <div class="flex items-center">
    <app-help-selector *ngIf="false" class="mr-1"></app-help-selector>
    <app-app-switch class="mr-2"></app-app-switch>
    <app-org-selector class="mx-2"></app-org-selector>
    <app-header-user-menu></app-header-user-menu>
  </div>
</div>

<div *ngIf="showcase && loaded" class="title-bar">
  <div class="flex items-center gap-1">
    <div class="flex items-center" *ngIf="currentSnapshot">
      <button mat-flat-button (click)="clearSnapshot()"><mat-icon>arrow_back_ios</mat-icon></button>
      <h6>{{ currentSnapshot.createdOn | date: 'medium' }}</h6>
      <div class="ml-5">
        <button data-test="snapshot-version" color="primary" disabled="true" mat-stroked-button>
          V{{ currentSnapshot.versionNumber || ' ' + (currentSnapshot.createdOn | date: 'short') }}
        </button>
      </div>
      <div class="ml-5" *ngIf="isSnapshotRestoreAllowed">
        <button data-test="restore-snapshot-button" color="primary" mat-stroked-button (click)="restoreSnapshot()">
          RESTORE VERSION
        </button>
      </div>
    </div>

    <ng-container *ngIf="!currentSnapshot">
      <img
        src="assets/images/showcase_icon.svg"
        alt="Showcase Icon"
        class="object-contain w-9 h-9"
        (click)="home()"
        data-test="showcase-home"
      />
      <div class="ml-4 title">
        <h6 *ngIf="!editName">{{ name }}</h6>
        <form [formGroup]="form">
          <mat-form-field *ngIf="editName">
            <input
              matInput
              #editNameInput
              type="text"
              formControlName="name"
              id="name"
              name="name"
              (blur)="endEditName()"
            />
          </mat-form-field>
        </form>
      </div>
      <div data-test="edit-showcase-button" *ngIf="editorMode === 'EDIT'">
        <button (click)="startNameEdit()" class="menu-button mat-button mat-button-base">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <app-menu name="" icon="settings">
        <app-showcase-configuration [showcase]="showcase"></app-showcase-configuration>
      </app-menu>
      <app-menu name="" [icon]="showcase.sourceAssortment ? 'link' : 'link_off'" data-test="source-assortment-button">
        <app-source-assortment
          data-test="app-source-assortment"
          (assignSourceAssortment)="assignSourceAssortment($event)"
          (removeSourceAssortment)="removeSourceAssorment()"
          [sourceAssortment]="showcase.sourceAssortment"
          [workspaceId]="showcase.workspaceId"
        >
        </app-source-assortment>
      </app-menu>
      <app-document-history-header-button
        *ngIf="editorMode === 'EDIT'"
        (openPanel)="openHistoryPanel($event)"
        [matTooltip]="'History'"
      >
      </app-document-history-header-button>
    </ng-container>
  </div>

  <div class="flex items-center gap-1 relative">
    <app-user-sessions-avatars
      *ngIf="editorMode === 'EDIT'"
      [clickableAvatar]="true"
      (handleClick)="this.navigateToUserFrame($event)"
    ></app-user-sessions-avatars>
    <app-header-clipboard
      *ngIf="editorMode === 'EDIT'"
      data-test="clipboard-button"
      matTooltip="Clipboard"
      (showClipboard)="onShowClipboard()"
    ></app-header-clipboard>
    <app-status-messages-indicator
      data-test="status-messages-button"
      *ngIf="editorMode !== 'VIEW'"
      [matTooltip]="'Alerts'"
      [messages]="messages$ | async"
      (onShowAlertMessages)="onShowAlertMessages($event)"
    ></app-status-messages-indicator>
    <app-header-comments
      *ngIf="editorMode !== 'VIEW'"
      data-test="comments-button"
      [allowToggleSideBar]="true"
      matTooltip="Comments"
    ></app-header-comments>
    <!-- <app-help-selector></app-help-selector> -->
    <app-header-user-menu class="px-1"></app-header-user-menu>
    <div class="flex items-center gap-2">
      <button
        mat-stroked-button
        color="primary"
        (click)="share()"
        data-test="share-button"
        *ngIf="editorMode === 'EDIT'"
      >
        <mat-icon>share</mat-icon> Share
      </button>
      <button mat-raised-button color="primary" (click)="preview()"><mat-icon>play_arrow</mat-icon> Launch</button>
    </div>
  </div>
</div>
