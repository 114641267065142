<div class="ml-3 flex items-center">
  <div class="mr-1 h-[40px] flex items-center" [matTooltip]="tooltipText" matTooltipClass="multiline-tooltip">
    <mat-icon data-test="regenerate-lineboard-options-summary">info_outline</mat-icon>
  </div>
  <button
    mat-icon-button
    matTooltip="Lineboard options"
    data-test="composer-toolbar-lineboard-options"
    (click)="editLineboardConfig()"
  >
    <mat-icon>tune</mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="Select all lineboard frames"
    data-test="lineboard-selectall-button"
    (click)="this.selectAllLineboardFrames()"
  >
    <mat-icon svgIcon="lineboard-selectall"></mat-icon>
  </button>
</div>
<mat-divider vertical="true" class="divider"></mat-divider>
