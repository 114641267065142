<div class="flex">
  <button
    class="border-style-button"
    #optionListMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="optionActionMenu"
    (menuOpened)="handleOptionListMenuOpened()"
    mat-icon-button
    matTooltip="Manage item & options"
    matTooltipPosition="above"
    data-test="item-option-context"
  >
    <mat-icon class="icons material-icons-outlined" svgIcon="hierarchy"></mat-icon>
  </button>

  <mat-menu #optionActionMenu="matMenu" class="border-style-menu text-black60 min-w-[250px]">
    <div *ngIf="queryItemOptionsInProgress" class="option" mat-menu-item>
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <div class="w-full h-full my-1" *ngIf="!queryItemOptionsInProgress">
      <div
        class="option"
        [disabled]="!contextualEntity?.optionName"
        data-test="item-component-remove-option"
        mat-menu-item
        (click)="removeOption()"
      >
        Switch to item level
      </div>
      <div
        class="option"
        [disabled]="itemOptions?.length === 0"
        data-test="item-component-switch-option"
        mat-menu-item
        [matMenuTriggerFor]="itemOptions?.length > 0 && optionAssignMenu"
      >
        Switch option
      </div>
      <div class="option" mat-menu-item data-test="item-component-add-to-clipboard" (click)="addToClipboard()">
        Add to clipboard
      </div>
    </div>
  </mat-menu>

  <mat-menu #optionAssignMenu="matMenu" class="text-black60">
    <div
      mat-menu-item
      class="option"
      [disabled]="option.id === contextualEntity.id"
      *ngFor="let option of itemOptions"
      (click)="assignOption(option)"
    >
      {{ option.optionName }}
    </div>
  </mat-menu>
</div>
