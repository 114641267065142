import { State } from './assortments.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as AssortmentsActions from './assortments.actions';
import { assortmentsEntityAdapter } from './assortments.state';
import { assortmentsInitialState } from './assortments.state';
import { backingAssortmentReducers } from './backing-assortment/backing-assortment.reducers';

const setData = (state: State, { data }) => {
  return {
    ...state,
    assortments: assortmentsEntityAdapter.setAll(data, state.assortments),
  };
};

const setSourceAssortment = (state: State, { sourceAssortment }) => {
  return {
    ...state,
    sourceAssortment,
  };
};

const clearSourceAssortment = (state: State) => {
  return {
    ...state,
    sourceAssortment: null,
  };
};

const setSourceAssortmentInfo = (state: State, { sourceAssortment }) => {
  return {
    ...state,
    sourceAssortmentInfo: sourceAssortment,
  };
};

export const assortmentsReducers = createReducer(
  assortmentsInitialState,
  ...backingAssortmentReducers,
  on(AssortmentsActions.loadAssortmentsSuccess, setData),
  on(AssortmentsActions.loadSourceAssortmentSuccess, setSourceAssortment),
  on(AssortmentsActions.loadSourceAssortmentInfoSuccess, setSourceAssortmentInfo),
  on(AssortmentsActions.clearSourceAssortment, clearSourceAssortment),
);

export function reducer(state: State | undefined, action: Action): any {
  return assortmentsReducers(state, action);
}
