import { Injectable } from '@angular/core';
import { Assets, Entities, EntityReference } from '@contrail/sdk';
import {
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentElementFactory,
  SizeDefinition,
} from '@contrail/documents';
import { DocumentService } from '../document.service';
import { AuthService } from '@common/auth/auth.service';
import { ObjectUtil } from '@contrail/util';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentAssetService {
  constructor(
    private http: HttpClient,
    private documentService: DocumentService,
    private authService: AuthService,
  ) {}

  async createElementFromContent(content, options, asset = null, config?: { scaleSize?: boolean }) {
    console.log('DocumentAssetService.createElementFromContent: ', content, options);

    let element: DocumentElement;
    let updateModelBindingPromise;
    if (content?.contentType === 'image/svg+xml') {
      this.documentService.toggleLoading(true, 'Uploading. Please wait...');
      const authContext = await this.authService.getAuthContext();
      const response = await fetch(content.primaryFileUrl, {
        headers: {
          'x-api-key': authContext.token,
          'x-api-org': authContext.currentOrg.orgSlug,
        },
      });
      const svgContent = await response.text();
      const svgElementResponse = await this.documentService.fileHandler.createSvgElementFromString(
        svgContent,
        options,
        content?.fileName,
      );
      element = svgElementResponse.element;
      updateModelBindingPromise = svgElementResponse.updateModelBindingPromise;
      this.documentService.toggleLoading(false, '');
    } else {
      let imageSize = await this.getImageDimensionsFromUrl(content.mediumViewableUrl);
      if (!imageSize) return;
      if (config?.scaleSize) {
        imageSize = this.getAdjustedImageSize(imageSize, { width: 300, height: 300 });
      }
      const modelBindings: any = { content: `content:${content.id}` };
      if (asset) {
        modelBindings.asset = `asset:${asset.id}`;
      }
      element = DocumentElementFactory.createImageElement({
        modelBindings,
        size: imageSize,
        position: options.position,
        propertyBindings: { url: 'content.mediumViewableUrl' },
        url: content.mediumViewableUrl,
      });
    }

    if (element) {
      this.documentService.deselectAllElements();
      const action = new DocumentAction(
        {
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: element,
          elementId: element.id,
        },
        {
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementId: element.id,
          elementData: ObjectUtil.cloneDeep(element),
        },
      );
      this.documentService.handleDocumentActions([action]);
    }
    if (updateModelBindingPromise) {
      await updateModelBindingPromise.then((documentAction) => {
        this.documentService.handleDocumentActions([documentAction]);
      });
    }
  }

  public async assignImageItemFromAsset(asset, element) {
    let content = asset.content;
    if (!content) {
      content = await new Entities().get({
        entityName: 'content',
        id: asset.contentId,
      });
    }
    this.documentService.handleDocumentElementEvent({
      element: element,
      data: [content],
      eventType: 'content_drag',
    });
  }

  async createElementFromAsset(asset, options, config?: { scaleSize?: boolean }) {
    let content = asset.content;
    if (!content) {
      content = await new Entities().get({
        entityName: 'content',
        id: asset.contentId,
      });
    }
    await this.createElementFromContent(content, options, asset, config);
  }

  /** Takes an image element to be an asset in the asset library
   * and then converts the element to be an asset component.
   */
  public async convertImageElementToAsset(element) {
    if (!element.modelBindings.image) {
      return;
    }
    const originalElement = ObjectUtil.cloneDeep(element);
    const modifiedElement = ObjectUtil.cloneDeep(element);

    const imageFileReference = new EntityReference(element.modelBindings.image);
    const asset = await new Assets().create({ fileId: imageFileReference.id });

    modifiedElement.modelBindings = {
      content: `content:${asset.contentId}`,
      asset: `asset:${asset.id}`,
    };
    modifiedElement.propertyBindings = {
      url: 'content.mediumViewableUrl',
    };
    modifiedElement.type = 'image';

    if (element) {
      const action = new DocumentAction(
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: modifiedElement,
          elementId: element.id,
        },
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementId: element.id,
          elementData: originalElement,
        },
      );
      this.documentService.handleDocumentActions([action]);
    }
  }

  private async getImageDimensionsFromUrl(url: string): Promise<SizeDefinition> {
    let imgSrc;
    if (url.includes('api.vibeiq.com') || url.includes('api.dev.vibeiq.com')) {
      const blob = await firstValueFrom(this.http.get(url, { responseType: 'blob' }));
      imgSrc = URL.createObjectURL(blob);
    } else {
      //TODO: if assets use always vibeiq url, no need
      imgSrc = await fetch(url).then((res) => res.url);
    }
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.onerror = (error) => reject(error);
      img.src = imgSrc;
    });
  }

  private getAdjustedImageSize(srcImageSize: SizeDefinition, imageSize: SizeDefinition): SizeDefinition {
    const widthScale = imageSize.width / srcImageSize.width;
    const heightScale = imageSize.height / srcImageSize.height;
    const scale = Math.min(widthScale, heightScale);
    return {
      width: srcImageSize.width * scale,
      height: srcImageSize.height * scale,
    };
  }
}
