<!--<app-mat-spinner [loading]="isLoading" [overlay]='true' *ngIf="isLoading" [message]="message"></app-mat-spinner>-->

<div id="frameTray" cdkScrollable>
  <div
    class="sortable-list"
    cdkDropList
    #dropList="cdkDropList"
    [cdkDropListAutoScrollStep]="10"
    (cdkDropListDropped)="drop($event)"
  >
    <ng-container *ngFor="let frame of presentation?.frames; trackBy: trackByFrameId; index as i">
      <div class="flex" [class.opacity-0]="dragging && selectedIds.includes(frame?.id)" [id]="frame?.id">
        <span class="nav-idx">{{ i + 1 }}</span>

        <div class="flex flex-col">
          <app-composer-frame-preview
            [frame]="frame"
            cdkDrag
            [cdkDragDisabled]="editorMode !== 'EDIT'"
            (cdkDragStarted)="cdkDragStarted($event, frame, dropList)"
            (cdkDragEnded)="cdkDragEnded($event)"
            [selected]="selectedIds.includes(frame?.id)"
            [lineboardSelected]="selectedLineboardFrameIds.includes(frame?.id)"
            [editorMode]="editorMode"
            (click)="selectFrame(frame, $event)"
            (contextmenu)="onContextMenu($event, frame, i)"
          >
            <div *cdkDragPreview class="dragPreviewContainer">
              <div id="cdkDragPreviewDiv"></div>
              <div *ngIf="selectedIds.length > 1" class="ghost-shadow shadow-one"></div>
              <div *ngIf="selectedIds.length > 2" class="ghost-shadow shadow-two"></div>
            </div>
          </app-composer-frame-preview>
          <app-composer-frame-placeholder
            [frame]="frame"
            [selectedPlaceholderFrame]="selectedPlaceholderFrame"
            (mousedown)="activatePlaceholder(frame)"
          ></app-composer-frame-placeholder>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="add-frame-container" *ngIf="editorMode === 'EDIT'">
    <app-add-frame-widget id="add-frame" *ngIf="!isLoading" icon="add_circle" [largeIcon]="true"></app-add-frame-widget>
  </div>
</div>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<mat-menu #contextMenu="matMenu" class="menu-md composer-frame-tray-context-menu">
  <ng-template matMenuContent let-frame="frame">
    <button mat-menu-item (click)="insertSlide(0)" data-test="insert-above" *ngIf="!multipleContext">
      <mat-icon class="icons" svgIcon="up-arrow"></mat-icon>
      <span>Insert above</span>
    </button>
    <button mat-menu-item (click)="insertSlide(1)" data-test="insert-below" *ngIf="!multipleContext">
      <mat-icon class="icons" svgIcon="down-arrow"></mat-icon>
      <span>Insert below</span>
    </button>
    <button mat-menu-item (click)="addComment(frame)" data-test="frame-tray-add-comment" *ngIf="!multipleContext">
      <mat-icon class="icons" svgIcon="contrail_comment"></mat-icon>
      <span>Comment</span>
    </button>
    <button mat-menu-item (click)="duplicateFrame(frame)" data-test="frame-tray-duplicate" *ngIf="!multipleContext">
      <mat-icon class="icons">content_copy</mat-icon>
      <span>Duplicate</span>
    </button>
    <button mat-menu-item (click)="deleteFrame(frame)" data-test="frame-tray-delete">
      <mat-icon class="icons">delete</mat-icon>
      <span>Delete <ng-container *ngIf="multipleContext">frames</ng-container></span>
    </button>
    <button mat-menu-item (click)="toggleSkipFrame(frame)" data-test="frame-tray-toggle-visibility">
      <mat-icon class="icons">{{ frame?.disabled ? 'visibility' : 'visibility_off' }}</mat-icon>
      <span>{{ frame?.disabled ? 'Show' : 'Hide' }}</span>
    </button>
    <button
      mat-menu-item
      (click)="saveAsTemplate(frame)"
      data-test="frame-tray-save-as-template"
      *ngIf="!multipleContext && frame.type === 'document'"
    >
      <mat-icon class="icons">dashboard</mat-icon>
      <span>Save as template</span>
    </button>
  </ng-template>
</mat-menu>
