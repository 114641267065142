import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AssortmentsActions } from '@common/assortments/assortments-store';
import { AssortmentsService } from '@common/assortments/assortments.service';
import { DownloadService } from '@common/exports/download/download.service';
import { WebSocketService } from '@common/web-socket/web-socket.service';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { ObjectUtil } from '@contrail/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ShowcasesActions } from '.';
import { ShowcasesService } from '../showcases.service';
import { Showcase } from './showcases.state';

@Injectable()
export class ShowcasesEffects {
  constructor(
    private actions$: Actions,
    private showcaseService: ShowcasesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private webSocketService: WebSocketService,
    private downloadService: DownloadService,
  ) {}

  loadShowcases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.LOAD_SHOWCASES),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showcaseService.getShowcases(store.workspaces.currentWorkspace.id)).pipe(
          map((data) => ShowcasesActions.loadShowcasesSuccess({ data })),
          catchError((error) => observableOf(ShowcasesActions.loadShowcasesFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentShowcase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.LOAD_CURRENT_SHOWCASE),
      switchMap((action: any) => {
        return from(this.showcaseService.getShowcaseById(action.id)).pipe(
          tap((showcase) => {
            const showSourceAssortmentWarning = showcase?.showSourceAssortmentWarning === true ? true : false;
            this.store.dispatch(ShowcasesActions.setShowSourceAssortmentWarning({ showSourceAssortmentWarning }));
          }),
          map((data) => ShowcasesActions.loadCurrentShowcaseSuccess({ showcase: data })),
          catchError((error) => observableOf(ShowcasesActions.loadCurrentShowcaseFailure({ error }))),
        );
      }),
    ),
  );
  createShowcase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.CREATE_SHOWCASE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const showcase: Showcase = { ...action.showcase };
        showcase.workspaceId = store.workspaces.currentWorkspace.id;
        return from(this.showcaseService.createShowcase(showcase)).pipe(
          tap((data) => {
            this.store.dispatch(WorkspacesActions.loadCurrentWorkspace({ id: data.rootWorkspaceId })); // Ensure workspace is this board workspace
          }),
          map((data) => {
            this.snackBar.open('Showcase Created.', '', { duration: 2000 });
            // this.store.dispatch(ShowcasesActions.setCurrentShowcase({ currentShowcase: data }));
            return ShowcasesActions.createShowcaseSuccess({ showcase: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(ShowcasesActions.createShowcaseFailure({ error }));
          }),
        );
      }),
    ),
  );
  copyShowcase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.COPY_SHOWCASE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const newWorkspaceId = store.workspaces.currentWorkspace.id;
        return from(this.showcaseService.copyShowcase(action.name, action.sourceId, newWorkspaceId)).pipe(
          map((data) => {
            this.snackBar.open('Showcase Copied.', '', { duration: 2000 });
            return ShowcasesActions.copyShowcaseSuccess({ showcase: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(ShowcasesActions.copyShowcaseFailure({ error }));
          }),
        );
      }),
    ),
  );
  asyncCopyShowcase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcasesActions.ShowcasesActionTypes.ASYNC_COPY_SHOWCASE),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          const newWorkspaceId = store.workspaces.currentWorkspace.id;
          return from(this.showcaseService.asyncCopyShowcase(action.name, action.sourceId, newWorkspaceId)).pipe(
            map((data) => {
              if (!data?.jobId) {
                throw new Error();
              }
              this.downloadService.initDownloadPolling(data.jobId, `/showcases/copy/${data.jobId}`);
              return data;
            }),
            catchError((error) => {
              this.snackBar.open(error, 'An error occurred while attempting to copy this document. Please try again.', {
                duration: 2000,
              });
              return observableOf(ShowcasesActions.copyShowcaseFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  asyncCopyShowcaseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcasesActions.ShowcasesActionTypes.ASYNC_COPY_SHOWCASE_SUCCESS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          if (action.path) {
            const response = await fetch(action.path);
            const showcase = await response.json();
            this.snackBar.open('Showcase Copied.', '', { duration: 2000 });
            this.store.dispatch(ShowcasesActions.copyShowcaseSuccess({ showcase }));
          }
        }),
      ),
    { dispatch: false },
  );
  deleteShowcase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.DELETE_SHOWCASE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showcaseService.deleteShowcase(action.showcase)).pipe(
          map((data) => {
            this.snackBar.open('Showcase Deleted.', '', { duration: 2000 });
            // this.store.dispatch(ShowcasesActions.setCurrentShowcase({ currentShowcase: null }));
            return ShowcasesActions.deleteShowcaseSuccess({ showcase: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(ShowcasesActions.deleteShowcaseFailure({ error }));
          }),
        );
      }),
    ),
  );
  updateShowcase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.UPDATE_SHOWCASE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showcaseService.updateShowcase(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Showcase Updated.', '', { duration: 2000 });
            return ShowcasesActions.updateShowcaseSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(ShowcasesActions.updateShowcaseFailure({ error }));
          }),
        );
      }),
    ),
  );

  clearShowcaseSourceAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.CLEAR_SHOWCASE_SOURCE_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showcaseService.updateShowcase(action.id, { sourceAssortmentId: null })).pipe(
          map((showcase) => ShowcasesActions.clearShowcaseSourceAssortmentSuccess({ showcase })),
          tap((showcaseAction) => {
            this.webSocketService.sendSessionEvent({
              eventType: 'CLEAR_SOURCE_ASSORTMENT',
              changes: ObjectUtil.cloneDeep(showcaseAction.showcase),
            });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(ShowcasesActions.updateShowcaseFailure({ error }));
          }),
        );
      }),
    ),
  );

  clearShowcaseSourceAssortmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcasesActions.ShowcasesActionTypes.CLEAR_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          this.store.dispatch(AssortmentsActions.loadSourceAssortmentInfoSuccess({ sourceAssortment: null }));

          const updatedShowcase = ObjectUtil.cloneDeep(store.showcases.currentShowcase);
          updatedShowcase.sourceAssortmentId = null;
          updatedShowcase.sourceAssortment = null;
          // replace current showcase in the store with the updated one
          this.store.dispatch(
            ShowcasesActions.updateShowcaseSuccess({
              id: updatedShowcase.id,
              changes: {
                sourceAssortmentId: null,
                sourceAssortment: null,
              },
            }),
          );
          this.store.dispatch(AssortmentsActions.loadSourceAssortmentSuccess({ sourceAssortment: null }));
          this.store.dispatch(ShowcasesActions.loadCurrentShowcaseSuccess({ showcase: updatedShowcase }));
        }),
      ),
    { dispatch: false },
  );

  assignShowcaseSourceAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.ASSIGN_SHOWCASE_SOURCE_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(
          this.showcaseService.updateShowcase(action.id, { sourceAssortmentId: action.sourceAssortmentId }),
        ).pipe(
          map((showcase) => ShowcasesActions.assignShowcaseAssortmentSuccess({ showcase })),
          tap((showcaseAction) => {
            this.webSocketService.sendSessionEvent({
              eventType: 'ASSIGN_SOURCE_ASSORTMENT',
              changes: ObjectUtil.cloneDeep(showcaseAction.showcase),
            });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(ShowcasesActions.updateShowcaseFailure({ error }));
          }),
        );
      }),
    ),
  );

  assignShowcaseSourceAssortmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.ASSIGN_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        // query for the newly assigned source assortment
        this.store.dispatch(
          AssortmentsActions.loadSourceAssortmentInfo({ sourceAssortmentId: action.showcase.sourceAssortmentId }),
        );
        return from(AssortmentsService.getAssortment(action.showcase.sourceAssortmentId)).pipe(
          map((newSourceAssortment) => {
            const updatedShowcase = ObjectUtil.cloneDeep(store.showcases.currentShowcase);
            updatedShowcase.sourceAssortment = newSourceAssortment;
            updatedShowcase.sourceAssortmentId = newSourceAssortment.id;
            // replace current showcase in the store with the updated one
            this.store.dispatch(
              ShowcasesActions.updateShowcaseSuccess({
                id: updatedShowcase.id,
                changes: {
                  sourceAssortmentId: updatedShowcase.sourceAssortmentId,
                  sourceAssortment: updatedShowcase.sourceAssortment,
                },
              }),
            );
            // replace the current sourceAssortment in the store with the new one
            this.store.dispatch(
              AssortmentsActions.loadSourceAssortmentSuccess({ sourceAssortment: updatedShowcase.sourceAssortment }),
            );
            return ShowcasesActions.loadCurrentShowcaseSuccess({ showcase: updatedShowcase });
          }),
        );
      }),
    ),
  );
}
