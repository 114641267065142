import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsService } from './common/analytics/analytics.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserAnalyticsService } from '@common/analytics/user-analytics.service';
import { Subscription, fromEvent, map, tap } from 'rxjs';
import { AuthSelectors } from '@common/auth/auth-store';
import { FeatureFlagActions } from '@common/feature-flags';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    private analytics: AnalyticsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userAnalyticsService: UserAnalyticsService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        map((selectAuthContext) => {
          if (selectAuthContext?.currentOrg?.orgSlug) {
            this.store.dispatch(FeatureFlagActions.loadFeatureFlags());
          }
        }),
      )
      .subscribe();

    this.matIconRegistry.addSvgIcon(
      'app-action-options',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/action_options.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-image-tool',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/image_Tool.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-layouts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/layouts.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-comments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/comments.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-more-options',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/more_horizontal.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'canvas',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/canvas.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'app-text-box-create',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/text_tool.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-text-create',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/text_create.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-text-tool-create',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/text_tool_create.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'app-shape-tool',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/shape_tool.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-tools-items',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/items.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-tools-material',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/material_library.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-tools-color',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/color_library.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-tools-clo-set',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/clo_set.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-tools-vtana',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/vtana.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-tools-googleDrive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/googleDrive.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-tools-dropbox',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/dropbox.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'notification_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/notification_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'comment_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/comment_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'pointer_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pointer.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'warning-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/warning-placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'warning-alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/warning_alert.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'item-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/item-placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'item-collection-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/item-collection-placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'grid-frame-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/grid-frame-placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'img-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/img-placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'collection-frame',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/collection-frame.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'collection-frame-dark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/collection-frame-dark.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'highlight',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/highlight_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'app-double-arrow-shape',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/double_arrow_exchange.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'app-right-arrow-shape',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/right_arrow_direction.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'app-rhombus-shape',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/rhombus_shape.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'comments_placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/comments_placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'border-style-dotted',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/border-style-dotted.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'border-style-dashed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/border-style-dashed.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'add_section',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/add_section.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'add_subsection',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/add_subsection.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'grid-frame',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/grid-frame.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'dropped',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/dropped.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'primary',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/primary.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'carryover',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/carryover.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-assortment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/assortment_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'iframe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/iframe.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'showroom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/showroom_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'format-paragraph',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/format_paragraph.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'text-formatting',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/text-formatting.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'lineboard-selectall',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/lineboard-selectall.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-item-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/item-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-content-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/content-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'up-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/up_arrow.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'down-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/down_arrow.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'magic',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/magic.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'app-frame-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/frame.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'frame-layout-small',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/frame_layout_small.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'frame-layout-small-with-panel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/frame_layout_small_with_panel.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'frame-layout-medium',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/frame_layout_medium.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'frame-layout-medium-with-panel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/frame_layout_medium_with_panel.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'frame-layout-large',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/frame_layout_large.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'frame-layout-large-with-panel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/frame_layout_large_with_panel.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'frame-layout-xlarge',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/frame_layout_xlarge.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'frame-layout-xlarge-with-panel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/frame_layout_xlarge_with_panel.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'tag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/tag.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'horizontal-orientation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/horizontal_orientation.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'vertical-orientation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/vertical_orientation.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'mask-objects',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/mask_objects.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'dashboard_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/dashboard_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'mouse-cursor',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/mouse-cursor.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'hierarchy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/hierarchy.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'lineboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/lineboard.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'clipboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/clipboard.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'placeholder-item-option-image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/image_item_option_placeholder_no_margin.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'placeholder-item-family-image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/image_item_family_placeholder_no_margin.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'project',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/project.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'multiple-elements',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/multiple_elements.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'group-objects',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/group_objects.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'ungroup-objects',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/ungroup_objects.svg'),
    );

    this.userAnalyticsService.setEventApplication('SHOWCASE_EDITOR');
  }

  public ngOnInit() {
    this.analytics.init();

    // Disable browser zoom on mouse wheel because it causes bad UX.
    // Since board is based on canvas zoom, users don't understand
    // where browser zoom came from and don't know how to reset it.
    // Test case - zoom in on open context menu.
    this.subscription.add(
      fromEvent(window, 'wheel', { passive: false })
        .pipe(
          tap((event: any) => {
            if (event.ctrlKey) {
              // ctrlKey is true for zoom
              const objHierarchy = event.path || (event.composedPath && event.composedPath());
              for (const obj in objHierarchy) {
                if (objHierarchy[obj].localName === 'app-composer-canvas') {
                  return;
                }
              }
              event.preventDefault();
            }
          }),
        )
        .subscribe(),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
