<div *ngIf="layout === 'menu'">
  <div class="widgets-list">
    <button class="tinymce-format-button" mat-icon-button [matMenuTriggerFor]="alignmentMenu">
      <mat-icon class="text-black60">{{ 'format_align_' + textAlign }}</mat-icon>
    </button>
  </div>

  <mat-menu #alignmentMenu="matMenu">
    <ng-template matMenuContent let-frame="frame">
      <button class="tinymce-format-button" mat-menu-item (click)="setValue('left')">
        <mat-icon class="icons" [ngClass]="textAlign === 'left' ? '!text-accent' : '!text-black60'"
          >format_align_left</mat-icon
        >
        <span>Left</span>
      </button>
      <button class="tinymce-format-button" mat-menu-item (click)="setValue('center')">
        <mat-icon class="icons" [ngClass]="textAlign === 'center' ? '!text-accent' : '!text-black60'"
          >format_align_center</mat-icon
        >
        <span>Center</span>
      </button>
      <button class="tinymce-format-button" mat-menu-item (click)="setValue('right')">
        <mat-icon class="icons" [ngClass]="textAlign === 'right' ? '!text-accent' : '!text-black60'"
          >format_align_right</mat-icon
        >
        <span>Right</span>
      </button>
    </ng-template>
  </mat-menu>
</div>

<div *ngIf="layout === 'list'">
  <button
    class="toggle-icon tinymce-format-button"
    [color]="textAlign === 'left' ? 'accent' : ''"
    (click)="setValue('left')"
    mat-icon-button
  >
    <mat-icon class="icons" [ngClass]="textAlign === 'left' ? '!text-accent' : '!text-black60'"
      >format_align_left</mat-icon
    >
  </button>
  <button
    class="toggle-icon tinymce-format-button"
    [color]="textAlign === 'center' ? 'accent' : ''"
    (click)="setValue('center')"
    mat-icon-button
  >
    <mat-icon class="icons" [ngClass]="textAlign === 'center' ? '!text-accent' : '!text-black60'"
      >format_align_center</mat-icon
    >
  </button>
  <button
    class="toggle-icon tinymce-format-button"
    [color]="textAlign === 'right' ? 'accent' : ''"
    (click)="setValue('right')"
    mat-icon-button
  >
    <mat-icon class="icons" [ngClass]="textAlign === 'right' ? '!text-accent' : '!text-black60'"
      >format_align_right</mat-icon
    >
  </button>
</div>
