<app-modal data-test="generate-frames-modal" [dialogRef]="dialogRef" (click)="handleClick()">
  <mat-stepper linear #stepper disableRipple class="stepper" [disableRipple]="true">
    <mat-step [stepControl]="valid1">
      <ng-template matStepLabel>Source</ng-template>
      <app-document-generator-source-selector
        *ngIf="!loading"
        #sourceSelector
        [currentWorkspaceId]="currentWorkspaceId"
        (selectSource)="handleSelectSource($event)"
        (changeSource)="setAssortment(null)"
      ></app-document-generator-source-selector>
    </mat-step>
    <mat-step [stepControl]="valid2">
      <ng-template matStepLabel>Grouping</ng-template>
      <div class="mb-2 text-lg font-medium">Select grouping</div>
      <div class="mb-7" *ngIf="itemChooserLevelSelectionActive">
        <div class="font-medium mb-1">LEVEL</div>
        <mat-radio-group [(ngModel)]="itemLevel" (change)="getFrameItemCounts()">
          <mat-radio-button value="option" data-test="create-lineboard-option-level"> Option </mat-radio-button>
          <mat-radio-button value="family" class="mt-1 ml-3" data-test="create-lineboard-family-level">
            Item
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="mb-5">
        <div>Select one or multiple properties to create sections within the generated layout.</div>
      </div>
      <div *ngFor="let grouping of groupings; let i = index" class="flex items-center">
        <app-autocomplete
          [attr.data-test]="'create-lineboard-grouping-level-' + (i + 1)"
          [value]="selectedProperties.get(i)"
          [label]="'Level ' + (i + 1)"
          [isRequired]="i === 0"
          [options]="availablePropertiesForGroup.get(i)"
          (valueSelected)="selectGroupingProperty($event, i)"
          [displayProperty]="'propertyDefinition.label'"
        >
        </app-autocomplete>

        <div *ngIf="i > 0">
          <mat-icon
            (click)="removeGrouping(i)"
            class="ml-2 mb-5 cursor-pointer"
            [attr.data-test]="'create-lineboard-remove-grouping-' + (i + 1)"
            >close</mat-icon
          >
        </div>
      </div>
      <div
        *ngIf="groupings.length < 5"
        class="w-[70px] text-[12px] text-primary cursor-pointer"
        (click)="addGrouping()"
        data-test="create-lineboard-add-grouping"
      >
        + Add level
      </div>
    </mat-step>
    <mat-step optional [stepControl]="valid2">
      <div class="overflow-y-scroll max-h-[400px]">
        <ng-template matStepLabel>Filters & Sort</ng-template>
        <div class="mb-2 text-lg font-medium">Filters & sort</div>
        <div class="mb-5">
          <div>Apply additional filters and sort to the source data.</div>
        </div>
        <div>
          <div class="font-medium">FILTERS</div>
          <!-- Filters / Sorts -->
          <app-filter-panel
            *ngIf="stepper.selectedIndex === 2"
            [equalsOnly]="false"
            [filterDefinition]="filterDefinition"
            [hideApplyOptions]="true"
            [sourceValues]="sourceValues"
            [propertyTypeDefaultFilterConditions]="propertyTypeDefaultFilterConditions"
            (filterCriteriaChange)="handleFilterChange($event)"
          >
          </app-filter-panel>
        </div>
        <div class="mt-5" *ngIf="sortProperties">
          <div class="font-medium">SORT</div>
          <app-sort-panel
            *ngIf="stepper.selectedIndex === 2"
            data-test="sort-panel"
            [properties]="sortProperties"
            [hideApplyOptions]="true"
            [currentSorts]="sortDefinitions"
            (performSort)="handleSortChange($event)"
          >
          </app-sort-panel>
        </div>
      </div>
    </mat-step>
    <mat-step optional [stepControl]="valid2">
      <div>
        <ng-template matStepLabel>Layout</ng-template>
        <div class="mb-2 text-lg font-medium">Layout Options</div>
        <div class="mb-5">
          <div>
            Frame layout determines the size and number of item cards for each frame. Optionally, apply a custom layout
            by selecting a frame template.
          </div>
        </div>
        <div *ngIf="frameTemplates?.length > 0">
          <div class="font-medium">FRAME TEMPLATE</div>
          <app-generator-frame-template-chooser
            #templateChooser
            [frameTemplates]="frameTemplates"
            (setFrameTemplate)="handleSetFrameTemplate($event)"
          ></app-generator-frame-template-chooser>
        </div>
        <div class="justify-between mb-5 min-h-[150px]">
          <div class="flex justify-between mt-4">
            <div>
              <div class="font-medium mb-2">FRAME LAYOUT</div>
              <mat-radio-group class="radio-group" [(ngModel)]="gridTemplateDimensions" (change)="getFrameItemCounts()">
                <mat-radio-button class="mt-1" [value]="option" *ngFor="let option of gridTemplateOptions"
                  >{{ option.label }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="w-[250px] mr-[10px]" *ngIf="!selectedFrameTemplate || selectedFrameTemplate?.id === 'default'">
              <mat-icon
                class="!w-[250px] !h-full !text-[300px]"
                svgIcon="{{ gridTemplateDimensions.layoutPreviewImage + (includeFramePanel ? '-with-panel' : '') }}"
              ></mat-icon>
            </div>
          </div>
        </div>
        <div class="mb-7 mr-2 min-h-[85px]">
          <div class="mb-3 font-medium">OTHER OPTIONS</div>
          <div *ngIf="!selectedFrameTemplate || selectedFrameTemplate?.id === 'default'">
            <mat-slide-toggle
              class="options"
              color="primary"
              labelPosition="before"
              [(ngModel)]="includeFramePanel"
              (change)="getFrameItemCounts()"
            >
              Display frame info panel
            </mat-slide-toggle>
          </div>
          <div>
            <mat-slide-toggle
              class="options"
              color="primary"
              labelPosition="before"
              [(ngModel)]="groupMultiSelectInSeparateFrame"
              (change)="getFrameItemCounts()"
            >
              Display multi-value properties in separate frames
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="valid1">
      <div class="overflow-y-scroll max-h-[400px]" (click)="handlePropertiesStepClick()">
        <ng-template matStepLabel>Properties</ng-template>
        <div class="my-3">
          <div class="mb-2 text-lg font-medium">Properties</div>
          <div class="mb-5">
            <div>Select properties to display on item cards and frame panel. Select from template when available.</div>
          </div>
          <div class="mb-2 font-medium">ITEM CARD PROPERTIES</div>
          <div class="flex justify-between">
            <div class="w-[350px]" *ngIf="selectedComponentProperties">
              <app-property-view-template-builder
                #itemCardViewDefinitionBuilder
                [availableViewProperties]="availableViewProperties"
                [defaultPropertyStyle]="defaultComponentPropertyStyle"
                [viewDefinitionApplicationViewSlug]="'common:item_component'"
                [selectedPropertiesList]="selectedComponentProperties"
                (propertiesChangedEvent)="handleItemCardPropertyChange($event)"
                [allowTemplateEdit]="isOrgAdmin"
              >
              </app-property-view-template-builder>
            </div>
            <div>
              <app-property-view-builder-preview
                [properties]="selectedComponentProperties"
              ></app-property-view-builder-preview>
            </div>
          </div>
        </div>
        <div class="my-3" *ngIf="includeFramePanel">
          <div class="mb-2 font-medium">FRAME PANEL PROPERTIES</div>
          <div class="flex justify-between">
            <div class="w-[350px]">
              <app-property-view-template-builder
                #framePanelViewDefinitionBuilder
                [availableViewProperties]="availablePropertyPanelProperties"
                [defaultPropertyStyle]="defaultPanelPropertyStyle"
                [viewDefinitionApplicationViewSlug]="'common:frame_panel'"
                (propertiesChangedEvent)="handlePanelPropertyChange($event)"
                [allowTemplateEdit]="isOrgAdmin"
              >
              </app-property-view-template-builder>
            </div>
            <div>
              <app-property-view-builder-preview
                *ngIf="includeFramePanel"
                [backgroundColor]="'black'"
                [properties]="panelPropertyTemplate?.properties"
              ></app-property-view-builder-preview>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-stepper>

  <!-- ACTIONS / NAVIGATION -->
  <div class="actions mt-3 px-[10px]">
    <div class="flex">
      <div class="count" matTooltip="Frame count" matTooltipPosition="above" data-test="create-lineboard-frame-count">
        <mat-icon svgIcon="app-frame-icon"></mat-icon>
        {{ !loadingFrameCounts ? frameCount : '' }}
        <mat-spinner *ngIf="loadingFrameCounts" diameter="20"></mat-spinner>
      </div>
      <div
        class="count ml-2"
        matTooltip="Item count"
        matTooltipPosition="above"
        data-test="create-lineboard-item-count"
      >
        <mat-icon svgIcon="tag"></mat-icon>
        {{ !loadingItemCounts ? itemCount : '' }}
        <mat-spinner *ngIf="loadingItemCounts" diameter="20"></mat-spinner>
      </div>
    </div>
    <div class="flex gap-2">
      <button mat-stroked-button type="button" (click)="cancel()">CANCEL</button>

      <button
        *ngIf="isSourceSelectorNext()"
        mat-flat-button
        color="primary"
        (click)="sourceSelector.nextStep()"
        [disabled]="sourceSelector.nextInValid()"
      >
        NEXT
      </button>

      <button
        *ngIf="canGoForward() && !isSourceSelectorNext()"
        mat-flat-button
        color="primary"
        data-test="create-lineboard-next-button"
        (click)="next()"
        [disabled]="!isValid()"
      >
        {{ !loadingAssortmentItems ? 'NEXT' : '' }}
        <div *ngIf="loadingAssortmentItems" class="w-[40px] flex justify-center">
          <mat-spinner diameter="20"></mat-spinner>
        </div>
      </button>
      <button
        *ngIf="canComplete()"
        mat-flat-button
        color="primary"
        type="submit"
        data-test="create-lineboard-submit-button"
        (click)="done()"
        [disabled]="frameCount === 0 || (includeFramePanel && !panelPropertyTemplate)"
      >
        DONE
      </button>
    </div>
  </div>
</app-modal>
