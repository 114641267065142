import { Component, Input } from '@angular/core';
import { ClipboardActions } from '@common/clipboard/clipboard-store';
import { Entities, EntityReference } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ContextualEntityHelper } from '../../../contextual-entity-helper';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { ObjectUtil } from '@contrail/util';
import { tap } from 'rxjs';
import { ComponentEditorService } from '../../../component-editor/component-editor.service';

@Component({
  selector: 'app-property-configurator-entity-option-details',
  templateUrl: './entity-option-details.component.html',
  styleUrls: ['./entity-option-details.component.scss'],
})
export class EntityOptionDetailsComponent {
  @Input() selectedElement: any;
  @Input() contextualEntity: any;

  itemOptions: any[];
  queryItemOptionsInProgress = false;

  constructor(
    private store: Store<RootStoreState.State>,
    private contextualEntityHelper: ContextualEntityHelper,
    private componentEditorService: ComponentEditorService,
  ) {}

  async handleOptionListMenuOpened() {
    if (!this.itemOptions) {
      const projectItemRef = new EntityReference(this.selectedElement.modelBindings.projectItem);
      this.queryItemOptionsInProgress = true;
      // This only returns options that are in the context project
      this.itemOptions = await this.contextualEntityHelper.getItemOptions(
        this.contextualEntity.itemFamilyId,
        projectItemRef.id,
      );
      this.queryItemOptionsInProgress = false;
    }
  }

  async addToClipboard() {
    if (!this.selectedElement?.modelBindings?.item) {
      return;
    }

    const itemId = this.selectedElement.modelBindings.item.split('item:')[1];
    const projectItemId = this.selectedElement?.modelBindings?.projectItem?.split('project-item:')[1];
    const clipboardItem = { itemId, projectItemId };

    this.store.dispatch(ClipboardActions.addItemsToClipboard({ clipboardItems: [clipboardItem] }));
  }

  public async assignOption(replacementItem, removeOption = false, projectItemId = null) {
    if (replacementItem.id === this.contextualEntity.id) {
      return;
    }
    const updateElement = ObjectUtil.cloneDeep(this.selectedElement);
    if (updateElement.modelBindings.assortment || updateElement.modelBindings.assortmentItem) {
      this.store
        .select(AssortmentsSelectors.sourceAssortmentItemData)
        .pipe(
          tap(async (sourceItemData) => {
            let replacementAI = null;
            let assortmentId = null;
            if (sourceItemData) {
              if (updateElement.modelBindings.assortment) {
                const assortmentReferenceEntity: EntityReference = new EntityReference(
                  updateElement.modelBindings.assortment,
                );
                assortmentId = assortmentReferenceEntity.id;
              } else {
                const referenceEntity: EntityReference = new EntityReference(
                  updateElement.modelBindings.assortmentItem,
                );
                // try to find the original item in the source assortment
                let originalAI = sourceItemData.find((itemData) => itemData.assortmentItem.id === referenceEntity.id);
                if (!originalAI) {
                  // if original item is not in the source assortment, try to find it with a query
                  originalAI = await new Entities().get({
                    entityName: 'assortment-item',
                    criteria: { ids: [referenceEntity.id] },
                  });
                }
                if (originalAI) {
                  assortmentId = originalAI.assortmentId;
                }
              }
              // try to find the replacement item in the source assortment
              replacementAI = sourceItemData.find(
                (itemData) => itemData.assortmentId === assortmentId && itemData.item.id === replacementItem.id,
              );
              if (!replacementAI) {
                // if replacement item is not in the source assortment, try to find it with a query
                const replacementAIs = await new Entities().get({
                  entityName: 'assortment-item',
                  criteria: { assortmentId, itemId: replacementItem.id },
                });
                if (replacementAIs.length > 0) {
                  replacementAI = replacementAIs[0];
                }
              }
            }

            if (!removeOption && !replacementAI) {
              // option is not in the original assortment
              assortmentId = null;
              //this.assortment = null;
            }
            await this.updateComponent(
              updateElement,
              replacementItem.id,
              assortmentId,
              replacementAI?.id,
              removeOption,
              projectItemId,
            );
          }),
        )
        .subscribe();
    } else {
      await this.updateComponent(updateElement, replacementItem.id, null, null, removeOption, projectItemId);
    }
  }

  public removeOption() {
    this.assignOption({ id: this.contextualEntity.itemFamilyId }, true);
  }

  private async updateComponent(
    updateElement,
    replacementItemId,
    assortmentId,
    assortmentItemId,
    removeOption = false,
    replacementProjectItemId = null,
  ) {
    if (updateElement.modelBindings.assortment) {
      updateElement.modelBindings.assortment = assortmentId ? `assortment:${assortmentId}` : null;
    }
    if (updateElement.modelBindings.assortmentItem) {
      updateElement.modelBindings.assortmentItem = assortmentItemId ? `assortment-item:${assortmentItemId}` : null;
    }

    updateElement.modelBindings.item = `item:${replacementItemId}`;
    updateElement.modelBindings.viewable = `item:${replacementItemId}`;
    const projectItemId = replacementProjectItemId
      ? 'project-item:' + replacementProjectItemId
      : updateElement.modelBindings.projectItem.substring(
          0,
          updateElement.modelBindings.projectItem.lastIndexOf(':') + 1,
        ) + replacementItemId;
    updateElement.modelBindings.projectItem = projectItemId;
    const bindingChanges = updateElement.modelBindings;
    await this.componentEditorService.updateComponentElement(this.selectedElement, bindingChanges);
  }

  preventClose(event: Event) {
    event.stopPropagation();
  }
}
