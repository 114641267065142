<div class="flex items-center px-1" cdkDrag cdkDragRootElement="app-property-configurator-bar">
  <mat-icon
    cdkDragHandle
    *ngIf="['ASSET', 'CONTENT', 'IMAGE'].includes(elementType)"
    class="icons material-icons-outlined element-icon"
  >
    image
  </mat-icon>
  <mat-icon *ngIf="elementType === 'COLOR'" cdkDragHandle class="element-icon"> palette </mat-icon>
  <app-item-details-status
    *ngIf="elementType === 'ITEM' && contextualEntity"
    class="status"
    cdkDragHandle
    [item]="contextualEntity"
    iconOnly="true"
  />

  <mat-spinner *ngIf="!name" diameter="20"></mat-spinner>
  <div
    *ngIf="!editItemFamilyName && name"
    (dblclick)="startEditItemFamilyName()"
    class="px-1 text-base whitespace-nowrap"
    [class.cursor-text]="elementType === 'ITEM' && !itemFamilyNameDisabled"
    [matTooltip]="name"
    matTooltipPosition="above"
    [matTooltipDisabled]="name.length < 15"
    data-test="entity-details-name"
  >
    {{ name | shorten: 15 }}
  </div>
  <div *ngIf="editItemFamilyName" class="pr-2">
    <mat-form-field class="edit-family-name">
      <input
        matInput
        #editItemFamilyNameInput
        spellcheck="false"
        type="input"
        size="15"
        (keydown.enter)="endEditItemFamilyName($event, true)"
        (keydown.esc)="endEditItemFamilyName($event)"
        [formControl]="itemFamilyNameInput"
        (blur)="endEditItemFamilyName($event, true)"
      />
    </mat-form-field>
  </div>

  <button
    *ngIf="canViewDetails"
    (click)="viewDetails()"
    mat-icon-button
    matTooltip="Open"
    matTooltipPosition="above"
    data-test="open-details-modal"
  >
    <mat-icon>launch</mat-icon>
  </button>
  <mat-divider vertical="true" class="divider"></mat-divider>

  <ng-container *ngIf="elementType === 'ITEM'">
    <div *ngIf="!queryEntitiesInProgress" class="flex">
      <app-property-configurator-entity-option-details
        *ngIf="itemContextFeatureActive"
        [contextualEntity]="contextualEntity"
        [selectedElement]="selectedElement"
      ></app-property-configurator-entity-option-details>

      <app-property-configurator-entity-project-details
        *ngIf="projectContextFeatureActive"
        [workspace]="currentShowcase?.workspace"
        [selectedElement]="selectedElement"
        [projectItem]="projectItem"
        [contextualEntity]="contextualEntity"
      ></app-property-configurator-entity-project-details>
      <mat-divider
        vertical="true"
        class="divider"
        *ngIf="itemContextFeatureActive || projectContextFeatureActive"
      ></mat-divider>
    </div>
    <div *ngIf="queryEntitiesInProgress" class="ml-3">
      <mat-spinner diameter="20"></mat-spinner>
    </div>

    <button
      class="border-style-button"
      *ngIf="viewableEntity"
      mat-icon-button
      matTooltip="Content details"
      matTooltipPosition="above"
      [matMenuTriggerFor]="imageConfig"
      data-test="item-content-open-menu"
    >
      <mat-icon class="icons material-icons-outlined">image</mat-icon>
    </button>
    <mat-menu #imageConfig="matMenu" class="entity-details-content-menu border-style-menu text-black60">
      <mat-icon class="icons material-icons-outlined opacity-50 !text-[22px] mx-2">image</mat-icon>
      <div
        *ngIf="viewableEntity?.fileName"
        class="text-base whitespace-nowrap"
        [matTooltip]="viewableEntity?.fileName"
        [matTooltipDisabled]="viewableEntity?.fileName?.length < 15"
      >
        {{ viewableEntity?.fileName | shorten: 15 }}
      </div>
      <mat-divider vertical="true" class="divider" *ngIf="viewableEntity?.fileName"></mat-divider>
      <button data-test="item-content-copy-image" mat-icon-button matTooltip="Copy Content" (click)="copyViewable()">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button
        data-test="composer-toolbar-image-options"
        mat-icon-button
        matTooltip="Content Options"
        (click)="openContentEditor()"
        *ngIf="viewableEntity?.contentType === 'image/svg+xml'"
      >
        <mat-icon>tune</mat-icon>
      </button>
      <app-property-configurator-download matTooltip="Download" [element]="selectedElement" />
    </mat-menu>
  </ng-container>
</div>
