import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { ComposerService } from '../../composer.service';
import { ComposerToolbarService } from '../composer-toolbar.service';
import { DocumentGenerationConfig } from '../../../document-generator/document-generator.interfaces';
import { Entities } from '@contrail/sdk';
import { ShowcaseGenerateFramesUtil } from '../../../document-generator/showcase-generate-frames-util';

@Component({
  selector: 'app-composer-toolbar-lineboard-options',
  templateUrl: './composer-toolbar-lineboard-options.component.html',
  styleUrls: ['./composer-toolbar-lineboard-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerToolbarLineboardOptionsComponent implements OnChanges {
  @Input() documentGenerationConfigId = null;
  documentGenerationConfig: DocumentGenerationConfig;
  groupingProperties = '';
  secondaryGrouping = '';

  get tooltipText(): string {
    if (!this.documentGenerationConfig) {
      return '';
    }
    return (
      `Level: ${this.documentGenerationConfig.itemLevel === 'option' ? 'Option' : 'Family'}\n` +
      `Layout: ${this.documentGenerationConfig.gridLayoutDimensions.label} ${this.documentGenerationConfig.gridLayoutOrientation}\n` +
      `Primary Groupings: ${this.groupingProperties}\n` +
      `Secondary Grouping: ${this.secondaryGrouping}\n` +
      `Updated: ${new Date(this.documentGenerationConfig.updatedOn).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}\n` +
      `Updated By: ${this.documentGenerationConfig.updatedBy?.email}`
    );
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private service: ComposerToolbarService,
    private composerService: ComposerService,
    // private breakpointObserver: BreakpointObserver,
  ) {}

  public ngOnChanges() {
    this.initConfig();
  }

  prevent($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  initConfig() {
    if (this.documentGenerationConfigId) {
      this.groupingProperties = '';
      this.secondaryGrouping = '';
      this.getDocumentGeneratorConfig();
    } else {
      this.documentGenerationConfig = null;
    }
  }

  editLineboardConfig() {
    this.service.showEditLineboardConfig();
  }

  selectAllLineboardFrames() {
    if (this.documentGenerationConfigId) {
      this.composerService.presentation.subscribe((pres) => {
        const frames = pres.frames.filter(
          (frame) => frame.documentGenerationConfigId === this.documentGenerationConfigId,
        );
        this.composerService.selectedFrameIdsSubject.next(frames.map((f) => f.id));
      });
    }
  }

  async getDocumentGeneratorConfig() {
    this.documentGenerationConfig = await new Entities().get({
      entityName: 'document-generation-config',
      id: this.documentGenerationConfigId,
      relations: ['updatedBy'],
    });
    const properties = await ShowcaseGenerateFramesUtil.generateProperties();
    this.documentGenerationConfig.groupingProperties.forEach((property) => {
      const selectedProp = properties.find((prop) => prop.propertyDefinition.slug === property.slug);
      this.groupingProperties =
        this.groupingProperties + (this.groupingProperties !== '' ? ', ' : '') + selectedProp.propertyDefinition.label;
    });
    if (this.documentGenerationConfig?.secondaryGroupingProperties?.length > 0) {
      const selectedProp = properties.find(
        (prop) => prop.propertyDefinition.slug === this.documentGenerationConfig.secondaryGroupingProperties[0].slug,
      );
      this.secondaryGrouping = selectedProp.propertyDefinition.label;
    }
    this.cdr.detectChanges();
  }
}
