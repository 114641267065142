import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComposerComponent } from './composer.component';
import { ComposerCanvasComponent } from './composer-frame/composer-svg-frame/composer-canvas/composer-canvas.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatIconModule } from '@angular/material/icon';
import { ComposerFrameTrayComponent } from './composer-frame-tray/composer-frame-tray.component';
import { ComposerFramePreviewComponent } from './composer-frame-tray/composer-frame-preview/composer-frame-preview.component';
import { ComposerSvgFrameComponent } from './composer-frame/composer-svg-frame/composer-svg-frame.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ComposerCollectionFrameComponent } from './composer-frame/composer-collection-frame/composer-collection-frame.component';
import { ComposerSvgFramePreviewComponent } from './composer-frame/composer-svg-frame/composer-svg-frame-preview/composer-svg-frame-preview.component';
import { ComposerCollectionFramePreviewComponent } from './composer-frame/composer-collection-frame/composer-collection-frame-preview/composer-collection-frame-preview.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ComposerFrameComponent } from './composer-frame/composer-frame.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { ComponentsModule } from '../../common/components/components.module';
import { PresentationCollectionEditorComponent } from './composer-frame/composer-collection-frame/presentation-collection-editor/presentation-collection-editor.component';
import { AddFrameWidgetComponent } from './composer-frame-tray/add-frame-widget/add-frame-widget.component';
import { NewCollectionFrameModalComponent } from './composer-frame/composer-collection-frame/new-collection-frame-modal/new-collection-frame-modal.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { UploadFileFrameModalComponent } from './composer-frame/upload-file-frame-modal/upload-file-frame-modal.component';
import { ComposerContextMenuComponent } from './composer-context-menu/composer-context-menu.component';
import { AddHotSpotModalComponent } from '../document/action-dispatchers/hot-spot/add-hot-spot-modal/add-hot-spot-modal.component';
import { ComposerFrameToolBarTrayComponent } from './composer-frame-toolbar/composer-frame-toolbar.component';
import { ComposerFrameToolbarNameComponent } from './composer-frame-toolbar/composer-frame-toolbar-name/composer-frame-toolbar-name.component';
import { ComposerFrameToolbarVisibilityComponent } from './composer-frame-toolbar/composer-frame-toolbar-visibility/composer-frame-toolbar-visibility.component';
import { ComposerFrameToolbarBackgroundcolorComponent } from './composer-frame-toolbar/composer-frame-toolbar-backgroundcolor/composer-frame-toolbar-backgroundcolor.component';
import { DocumentModule } from '../document/document.module';
import { ActionsModule } from '../document/action-dispatchers/actions.module';
import { SidePanelOptionsComponent } from './side-panel/side-panel-options/side-panel-options.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { EntityChooserModule } from '../entity-chooser/entity-chooser.module';
import { ComposerFramePlaceholderComponent } from './composer-frame-tray/composer-frame-placeholder/composer-frame-placeholder.component';
import { ComposerManagerMessageHandler } from './composer-manager-message-handler';
import { ComponentEditorModule } from '../document/component-editor/component-editor.module';
import { UndoRedoModule } from '../../common/undo-redo/undo-redo-module';
import { MatBadgeModule } from '@angular/material/badge';
import { ComposerToolbarComponent } from './composer-toolbar/composer-toolbar-component/composer-toolbar.component';
import { ComposerToolbarAddElementComponent } from './composer-toolbar/composer-toolbar-add-element/composer-toolbar-add-element.component';
import { ComposerToolbarArrangeElementComponent } from './composer-toolbar/composer-toolbar-arrange-element/composer-toolbar-arrange-element.component';
import { ComposerToolbarCopyPasteComponent } from './composer-toolbar/composer-toolbar-copy-paste/composer-toolbar-copy-paste.component';
import { ComposerToolbarUndoRedoComponent } from './composer-toolbar/composer-toolbar-undo-redo/composer-toolbar-undo-redo.component';
import { PropertyConfiguratorModule } from '../document/property-configurator/property-configurator.module';
import { UploadImageFileModalComponent } from './composer-toolbar/upload-image-file-modal/upload-image-file-modal.component';
import { ComposerFrameSettingsComponent } from './composer-frame-toolbar/composer-frame-settings/composer-frame-settings.component';
import { ComposerToolbarPasteModalComponent } from './composer-toolbar/composer-toolbar-copy-paste/composer-toolbar-paste-modal-component';
import { ItemsModule } from 'src/app/common/items/items.module';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { PresentationCollectionItemComponent } from './composer-frame/composer-collection-frame/presentation-collection-item/presentation-collection-item.component';
import { TypesModule } from '@common/types/types.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { ItemPresentationModule } from '@common/item-presentation/item-presentation.module';
import { PresentationPreviewModalComponent } from './composer-frame/composer-collection-frame/composer-collection-frame-presentation-preview/presentation-preview-modal.component';
import { CommentsModule } from 'src/app/common/comments/comments.module';
import { ComposerCommentsListComponent } from './composer-comments/composer-comments-list.component';
import { ComposerPinnedComments } from 'src/app/presentation/composer/composer-pinned-comments/composer-pinned-comments.component';
import { ComposerFramePinnedComments } from 'src/app/presentation/composer/composer-pinned-comments/composer-frame-pinned-comments/composer-frame-pinned-comments.component';
import { ComposerCollectionItemPinnedComments } from 'src/app/presentation/composer/composer-pinned-comments/composer-collection-item-pinned-comments/composer-collection-item-pinned-comments.component';
import { ComposerGridFrameComponent } from './composer-frame/composer-grid-frame/composer-grid-frame.component';
import { ComposerGridFrameEditorComponent } from './composer-frame/composer-grid-frame/composer-grid-frame-editor/composer-grid-frame-editor.component';
import { ComposerGridFrameSectionComponent } from './composer-frame/composer-grid-frame/composer-grid-frame-section/composer-grid-frame-section.component';
import { ComposerToolbarGridFrameComponent } from './composer-toolbar/composer-toolbar-grid-frame/composer-toolbar-grid-frame.component';
import { ContextMenuModule } from '@common/components/context-menu/context-menu.module';
import { CreateGridFrameSectionComponent } from './composer-frame/composer-grid-frame/composer-grid-frame-section/create-grid-frame-section/create-grid-frame-section.component';
import { UpdateGridFrameSectionComponent } from './composer-frame/composer-grid-frame/composer-grid-frame-section/update-grid-frame-section/update-grid-frame-section.component';
import { NewGridFrameModalComponent } from './composer-frame/composer-grid-frame/new-grid-frame-modal/new-grid-frame-modal.component';
import { ComposerGridFramePreviewComponent } from './composer-frame/composer-grid-frame/composer-grid-frame-preview/composer-grid-frame-preview.component';
import { ComposerToolbarAnnotationComponent } from './composer-toolbar/composer-toolbar-annotation/composer-toolbar-annotation.component';
import { DocumentStatusMessageComponent } from '../document/document-status/document-status-message-component/document-status-message.component';
import { AnnotationModule } from '@common/annotation/annotation.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ComposerFrameLayoutComponent } from './composer-frame/composer-frame-layout/composer-frame-layout.component';
import { ComposerFramePropertyConfiguratorComponent } from './composer-frame/composer-frame-layout/composer-frame-property-configurator/composer-frame-property-configurator.component';
import { ComposerFramePropertySelectorComponent } from './composer-frame/composer-frame-layout/composer-frame-property-selector/composer-frame-property-selector.component';
import { ComposerFrameCreateTemplateViewComponent } from './composer-frame/composer-frame-layout/create-template-view/create-template-view.component';
import { DocumentHistoryModule } from '@common/document-history/document-history.module';
import { GridFrameDimensionValuePickerComponent } from './composer-frame/composer-grid-frame/new-grid-frame-modal/grid-frame-dimension-value-picker/grid-frame-dimension-value-picker.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ExportsModule } from 'src/app/common/exports/exports.module';
import { SearchReplaceModule } from '@common/search-replace/search-replace.module';
import { AssortmentsModule } from '@common/assortments/assortments.module';
import { ComposerToolbarItemComponent } from './composer-toolbar/composer-toolbar-item/composer-toolbar-item.component';
import { ComposerToolbarLineboardOptionsComponent } from './composer-toolbar/composer-toolbar-lineboard-options/composer-toolbar-lineboard-options.component';
import { PipesModule } from '@pipes/pipes.module';
import { CollectionStatusMessageModule } from '@common/collection-status-message/collection-status-message.module';
import { ComposerCanvasNavigationBarComponent } from './composer-frame/composer-svg-frame/composer-canvas-navigation-bar/composer-canvas-navigation-bar.component';
import { ComposerGridSectionPinnedComments } from './composer-pinned-comments/composer-grid-section-pinned-comments/composer-grid-section-pinned-comments.component';
import { NewShowroomFrameModalComponent } from './composer-frame/composer-showroom-frame/new-showroom-frame-modal/new-showroom-frame-modal.component';
import { ComposerShowroomFramePreviewComponent } from './composer-frame/composer-showroom-frame/composer-showroom-frame-preview/composer-showroom-frame-preview.component';
import { ComposerShowroomFrameComponent } from './composer-frame/composer-showroom-frame/composer-showroom-frame.component';
import { ShowroomViewerModule } from '@common/showroom-viewer/showroom-viewer.module';
import { ObjectReferenceModule } from '@common/object-reference/object-reference.module';
import { NewIframeFrameModalComponent } from './composer-frame/composer-iframe-frame/new-iframe-frame-modal/new-iframe-frame-modal.component';
import { ComposerIframeFrameComponent } from './composer-frame/composer-iframe-frame/composer-iframe-frame.component';
import { ComposerIframeFramePreviewComponent } from './composer-frame/composer-iframe-frame/composer-iframe-frame-preview/composer-iframe-frame-preview.component';
import { DocumentGeneratorModule } from '../document-generator/document-generator.module';
import { ComposerToolbarMaskElementComponent } from './composer-toolbar/composer-toolbar-mask-element/composer-toolbar-mask-element.component';
import { FrameTemplatesModule } from '@common/frame-templates/frame-templates.module';
import { DocumentTemplatesModule } from '../document-templates/document-templates.module';
import { DashboardModule } from '@common/dashboard/dashboard.module';
import { ComposerDashboardPanelComponent } from './composer-dashboard-panel/composer-dashboard-panel.component';
import { ComposerExportComponent } from './composer-export/composer-export.component';
import { RemoteMouseTrackerModule } from '@common/remote-mouse-tracker/remote-mouse-tracker.module';
import { ComposerFrameRemoteSessions } from './composer-frame-remote-sessions/composer-frame-remote-sessions.component';
import { UserSessionsModule } from '@common/user-sessions/user-sessions.module';
import { ComposerFrameLayoutService } from './composer-frame/composer-frame-layout/composer-frame-layout.service';
import { ComposerGridFrameService } from './composer-frame/composer-grid-frame/composer-grid-frame.service';
import { ChooserModule } from '@common/chooser/chooser.module';
import { ColorModule } from '@common/color/color.module';

@NgModule({
  declarations: [
    ComposerComponent,
    ComposerCanvasComponent,
    ComposerFrameTrayComponent,
    ComposerFramePreviewComponent,
    ComposerFramePlaceholderComponent,
    ComposerSvgFrameComponent,
    ComposerCanvasNavigationBarComponent,
    ComposerCollectionFrameComponent,
    ComposerGridFrameComponent,
    ComposerGridFrameEditorComponent,
    ComposerGridFrameSectionComponent,
    ComposerFrameLayoutComponent,
    ComposerFramePropertySelectorComponent,
    ComposerFramePropertyConfiguratorComponent,
    ComposerFrameCreateTemplateViewComponent,
    NewGridFrameModalComponent,
    GridFrameDimensionValuePickerComponent,
    CreateGridFrameSectionComponent,
    UpdateGridFrameSectionComponent,
    ComposerSvgFramePreviewComponent,
    ComposerCollectionFramePreviewComponent,
    ComposerGridFramePreviewComponent,
    ComposerFrameComponent,
    PresentationCollectionEditorComponent,
    PresentationCollectionItemComponent,
    AddFrameWidgetComponent,
    NewCollectionFrameModalComponent,
    UploadFileFrameModalComponent,
    ComposerContextMenuComponent,
    // AddHotSpotModalComponent,
    ComposerFrameToolBarTrayComponent,
    ComposerFrameToolbarNameComponent,
    ComposerFrameToolbarVisibilityComponent,
    ComposerFrameToolbarBackgroundcolorComponent,
    SidePanelOptionsComponent,
    SidePanelComponent,
    DocumentStatusMessageComponent,
    ComposerToolbarComponent,
    ComposerToolbarAddElementComponent,
    ComposerToolbarArrangeElementComponent,
    ComposerToolbarCopyPasteComponent,
    ComposerToolbarUndoRedoComponent,
    ComposerToolbarAnnotationComponent,
    ComposerToolbarGridFrameComponent,
    ComposerToolbarItemComponent,
    ComposerToolbarLineboardOptionsComponent,
    ComposerToolbarMaskElementComponent,
    UploadImageFileModalComponent,
    ComposerFrameSettingsComponent,
    ComposerToolbarPasteModalComponent,
    PresentationPreviewModalComponent,
    ComposerPinnedComments,
    ComposerFramePinnedComments,
    ComposerCollectionItemPinnedComments,
    ComposerGridSectionPinnedComments,
    ComposerCommentsListComponent,
    NewShowroomFrameModalComponent,
    ComposerShowroomFramePreviewComponent,
    ComposerShowroomFrameComponent,
    NewIframeFrameModalComponent,
    ComposerIframeFrameComponent,
    ComposerIframeFramePreviewComponent,
    ComposerDashboardPanelComponent,
    ComposerExportComponent,
    ComposerFrameRemoteSessions,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    FormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    PipesModule,
    DragDropModule,
    ColorPickerModule,
    OverlayModule,
    MatMenuModule,
    MatDividerModule,
    ComponentsModule,
    NgxDropzoneModule,
    ReactiveFormsModule,
    UserSessionsModule,
    RemoteMouseTrackerModule,
    DocumentModule,
    ActionsModule,
    MatTooltipModule,
    EntityChooserModule,
    ComponentEditorModule,
    UndoRedoModule,
    MatBadgeModule,
    MatListModule,
    ItemsModule,
    PropertyConfiguratorModule,
    VirtualScrollerModule,
    ComponentsModule,
    MatTabsModule,
    TypesModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    ItemPresentationModule,
    CommentsModule,
    ContextMenuModule,
    AnnotationModule,
    MatDialogModule,
    DocumentHistoryModule,
    ExportsModule,
    SearchReplaceModule,
    AssortmentsModule,
    CollectionStatusMessageModule,
    ShowroomViewerModule,
    ObjectReferenceModule,
    DocumentGeneratorModule,
    FrameTemplatesModule,
    DocumentTemplatesModule,
    DashboardModule,
    ChooserModule,
    DocumentGeneratorModule,
    ColorModule,
  ],
  exports: [ComposerComponent, ComposerFrameToolBarTrayComponent, ComposerToolbarComponent],
  providers: [ComposerFrameLayoutService, ComposerGridFrameService],
})
export class ComposerModule {
  constructor(private messageHandler: ComposerManagerMessageHandler) {}
}
