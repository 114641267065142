import { Injectable } from '@angular/core';
import { Request } from '@contrail/sdk';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { Subject } from 'rxjs/internal/Subject';

export interface CompletedJob {
  jobId: string;
  path: string | Blob;
  name?: string;
}

export interface PendingJob {
  jobId: string;
  requestUrl?: string;
}

export enum ExportJobStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed',
}

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  // total timeout = 60 seconds
  private TIMEOUT_POLLING_COUNT_LIMIT = 40;
  private DEBOUNCE_TIME = 2000;

  private jobPollingCount = new Map<string, number>();

  private pendingJobs: Array<PendingJob> = [];
  private pendingJobsSubject: Subject<PendingJob[]> = new BehaviorSubject(null);
  pendingJobsObservable: Observable<PendingJob[]> = this.pendingJobsSubject.asObservable();

  private completedJobs: CompletedJob = null;
  private completedJobsSubject: Subject<CompletedJob> = new Subject();
  completedJobsObservable$: Observable<CompletedJob> = this.completedJobsSubject.asObservable();

  private errorSubject: Subject<boolean> = new BehaviorSubject(null);
  errorObservable$: Observable<boolean> = this.errorSubject.asObservable();

  private initiateDownloadSubject: Subject<PendingJob> = new BehaviorSubject(null);
  initiateDownloadObservable$: Observable<PendingJob> = this.initiateDownloadSubject.asObservable();

  public downloading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
    this.initiateDownloadObservable$.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe((value) => {
      if (value) {
        this.getDownloadedURL(value);
      }
    });
  }

  public addPendingJob(jobId: string, requestUrl?: string) {
    this.pendingJobs.push({ jobId, requestUrl });
    this.pendingJobsSubject.next(this.pendingJobs);
  }

  public setError(jobId) {
    this.pendingJobs = this.pendingJobs.filter((data) => data.jobId !== jobId);
    this.pendingJobsSubject.next(this.pendingJobs);

    this.errorSubject.next(true);
    this.downloading$.next(false);
  }

  public completePendingJob(jobId: string, jobStatusData: CompletedJob) {
    this.pendingJobs = this.pendingJobs.filter((data) => data.jobId !== jobId);

    // if jobStatusData is null, it means the job failed
    if (jobStatusData) {
      this.completedJobs = jobStatusData;
      this.completedJobsSubject.next(this.completedJobs);
    }

    this.pendingJobsSubject.next(this.pendingJobs);
    this.downloading$.next(false);
  }

  initDownloadPolling(jobId: string, requestUrl?: string) {
    this.addPendingJob(jobId, requestUrl);

    this.initiateDownloadSubject.next({ jobId, requestUrl });
    this.updatePollingCount({ jobId, requestUrl });
  }

  async getDownloadedURL({ jobId, requestUrl }: PendingJob) {
    this.downloading$.next(true);

    const url = requestUrl ?? `/exports/${jobId}`;
    const jobStatusData = await Request.request(url, {});

    if (jobStatusData && jobStatusData?.status === ExportJobStatus.COMPLETED) {
      this.completePendingJob(jobId, { jobId, path: jobStatusData?.path });
    }

    if (jobStatusData?.status === ExportJobStatus.FAILED) {
      this.setError(jobId);
    }

    if (this.pendingJobs.length > 0) {
      const nextPendingJob: PendingJob = this.pendingJobs[0];
      try {
        this.updatePollingCount(nextPendingJob);
        this.initiateDownloadSubject.next(nextPendingJob);
      } catch (err) {
        console.error(' Error downloading job with jobId:' + nextPendingJob, err);
        this.setError(nextPendingJob);

        if (this.pendingJobs?.length) {
          this.initiateDownloadSubject.next(this.pendingJobs[0]);
        }
      }
    }
  }

  private updatePollingCount(pendingJob: PendingJob) {
    let count = 0;
    if (this.jobPollingCount.has(pendingJob.jobId)) {
      count = this.jobPollingCount.get(pendingJob.jobId);
    }

    count += 1;
    this.jobPollingCount.set(pendingJob.jobId, count);
    if (count > this.TIMEOUT_POLLING_COUNT_LIMIT) {
      // remove the job from the queue to exit infinite polling
      this.completePendingJob(pendingJob.jobId, null);
      throw Error(
        ` Export  timed out for jobid ${pendingJob.jobId}. Exceeded ${this.TIMEOUT_POLLING_COUNT_LIMIT} polling attempts with debounce duration of ${this.DEBOUNCE_TIME / 1000} seconds`,
      );
    }
  }

  public resetJobQueue() {
    this.pendingJobsSubject.next([]);
    this.completedJobsSubject.next(null);
    this.errorSubject.next(false);

    this.jobPollingCount = new Map<string, number>();
    this.pendingJobs = [];
  }
}
