<div *ngIf="layout === 'menu'">
  <div class="widgets-list">
    <button class="tinymce-format-button" mat-icon-button [matMenuTriggerFor]="listTypeMenu">
      <mat-icon *ngIf="listType !== 'decimal'" [ngClass]="listType === 'circle' ? 'text-accent' : 'text-black60'"
        >format_list_bulleted</mat-icon
      >
      <mat-icon *ngIf="listType === 'decimal'" [ngClass]="listType === 'decimal' ? 'text-accent' : 'text-black60'"
        >format_list_numbered</mat-icon
      >
    </button>
  </div>

  <mat-menu #listTypeMenu="matMenu">
    <ng-template matMenuContent let-frame="frame">
      <button class="tinymce-format-button" mat-menu-item (click)="setValue('circle')">
        <mat-icon class="!mr-0 icons" [ngClass]="listType === 'circle' ? 'text-accent' : 'text-black60'"
          >format_list_bulleted</mat-icon
        >
      </button>
      <button class="tinymce-format-button" mat-menu-item (click)="setValue('decimal')">
        <mat-icon class="!mr-0 icons" [ngClass]="listType === 'decimal' ? 'text-accent' : 'text-black60'"
          >format_list_numbered</mat-icon
        >
      </button>
    </ng-template>
  </mat-menu>
</div>
<div *ngIf="layout === 'list'">
  <button class="tinymce-format-button" mat-icon-button (click)="setValue('circle')">
    <mat-icon class="!mr-0 icons" [ngClass]="listType === 'circle' ? 'text-accent' : 'text-black60'"
      >format_list_bulleted</mat-icon
    >
  </button>
  <button class="tinymce-format-button" mat-icon-button (click)="setValue('decimal')">
    <mat-icon class="!mr-0 icons" [ngClass]="listType === 'decimal' ? 'text-accent' : 'text-black60'"
      >format_list_numbered</mat-icon
    >
  </button>
</div>
